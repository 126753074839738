* {
  box-sizing: border-box;
}

body {
  text-align: center;
  margin:0;
  background-color: #fff;
}

#root {
margin:0 2em;
}

#header {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.Row {
  display: flex;
  align-items: center;
  justify-content: left;
  width:auto;
}

.Row-letter {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1;
  width: 40px;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 3px;
}
.Row-letter-color {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.Row-annotation {
  margin-inline-start: 16px;
  flex: 1 1 0px;
  overflow: visible;
  font-size:90%;
  text-align: start;
}

.Row-annotation a, .hint a, .suggestion a {
  background-image:url(external-link-ltr-icon.svg);
  background-position:center right;
  background-repeat:no-repeat;
  padding-right:13px
}




.App-container {
  position: relative;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

#surrey-integration {
  background-color: #004682;
  width: auto;
  padding: 1em 5% 0 5%;
  margin: 0 0 1em 0;
  height: 5.5em;
  text-align: left;
  border-bottom: 1em solid black;
}

#surrey-integration img {
  height: 3em;
}

.App-container h1 {
  margin-top: 0;
  font-family: Roboto Black, "DejaVu Sans", sans-serif;
}

.Game,
h1 {
  user-select: none;
  display: flex;
  align-items:center;
  margin:0 auto .5em auto;
}

h1 img.logo {
  margin-right: .2em;
  height: 1em;
  margin-top: .6em;
}

h1 .gametitle {
}

.Game {
  display: flex;
  flex-direction: column;
}

table.Game-rows {
  margin: auto;
  display: flex;
  flex-direction: column;
}

table.Game-rows:focus {
  outline: none;
}

table.Game-rows > tbody {
  display: flex;
  flex-direction: column;
}

.Game-keyboard {
  display: flex;
  flex-direction: column;
}

.Game-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.Game-keyboard-button {
  margin: 2px;
  background-color: #cdcdcd;
  padding: 2px;
  /*text-transform: capitalize;*/
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 20px;
  color: inherit;
  text-decoration: inherit;
  border: 2px solid transparent;
  cursor: pointer;
}

.Game-keyboard-button-wide {
  flex: 2;
}

.Game-keyboard-button:focus {
  outline: none;
}

.letter-correct {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(87, 172, 120);
  color: white !important;
}

.letter-elsewhere {
  border: 2px dotted rgba(0, 0, 0, 0.3);
  background-color: #e9c601;
  color: white !important;
}

.letter-absent {
  border: 2px solid transparent;
  background-color: rgb(162, 162, 162);
  color: white !important;
}

body.dark {
  background-color: #404040;
  color: #f0f0f0;
  color-scheme: dark;
}

body.dark .Game-keyboard-button {
  color: #404040;
}
body.dark a,
body.dark a:visited {
  color: #d4d4ff;
}

body.dark a:active {
  color: #dda5ff;
}


a,
a:visited {
  color: #8080ff;
}

a:active {
  color: #cc77ff;
}

.Game-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.Game-options > * + * {
  margin-inline-start: 0.5rem;
}

.Game-options input#wordLength {
    max-width: 50px;
}
.Game-options button {
  min-width: 4rem;
}

.App-footer {
  margin: -1rem 0 2rem 0;
  font-size: 80%;
  line-height: 1.5;
}

.App-about {
  margin-top: -1rem;
  line-height: 1.4;
  padding: 0 .5em;
}

.App-about p {
  text-align: justify;
}

.App-about b {
  background-color: #888;
  color: white;
  padding: 1px 7px;
  font-size: 140%;
  border-radius: 1px;
}

.App-about b.green-bg {
  background-color: rgb(87, 172, 120);
}

.App-about b.yellow-bg {
  background-color: #e9c601;
}

.Game-seed-info {
  opacity: 0.7;
  margin-top: 1em;
  font-variant-numeric: tabular-nums;
}

.Game-sr-feedback,
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.Settings {
  text-align: left;
  font-size: 18px;
  padding: 0 .5em;
}

.Settings-setting {
  margin: 8px;
  display: flex;
  align-items: top;
}

.Settings-setting input {
  width: auto;
  height: 18px;
}

.Settings-setting label {
  margin-inline-start: 8px;
  margin-inline-end: 8px;
}

.top-right {
}

.emoji-link {
  font-size: 28px;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: transparent;
  text-shadow: 0 0 0 black;
}


body.dark .emoji-link {
  text-shadow: 0 0 0 white;

}
.top-right > * + * {
  margin-inline-start: 8px;
}

.App-container.color-blind .letter-correct,
.App-container.color-blind .App-about b.green-bg {
  background-color: #f5793a;
}

.App-container.color-blind .letter-elsewhere,
.App-container.color-blind .App-about b.yellow-bg {
  background-color: #85c0f9;
}

._2FKZL .suggestion a,
._2FKZL .suggestion a:active,
._2FKZL .suggestion a:hover,
._2FKZL .suggestion a:visited  {
  color: inherit;
  pointer-events: none;
  background-image:none;
}
.word {
  font-size: 120%;
}
.spoiler-text {
  cursor: pointer;
}
@media screen and (max-width:500px) {

  #root {
    margin: 0 .5em;
  }


.App-container {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
}

body {
  font-size:11pt;
}

.Game-keyboard-button {
  font-size: 12pt;
}

.Settings-setting input#difficulty-setting {
  width: 4em;
}

#surrey-integration {
  padding: .5em 5% 0 5%;
  height: 4em;
}

#surrey-integration img {
  height: 2em;
}

}


@media screen and (max-width:360px) {

body {
  font-size:10pt;
}

.Game-keyboard-button {
  font-size: 11pt;
}

}